<template>
    <UiCoreSimpleTitle v-editable="blok" class="sb-core-simple-title" data-test="sb-core-simple-title" :blok="blok" />
</template>

<script setup lang="ts">
import type { ICoreSimpleTitle } from '~ui/types';

defineProps<{ blok: ICoreSimpleTitle }>();
</script>

<style scoped lang="postcss"></style>
