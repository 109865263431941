<template>
    <div class="core-layout-mobile-carousel">
        <LazyClientOnly>
            <Carousel
                ref="carouselRef"
                :items-to-show="1"
                class="transfer-options-carousel"
                :wrap-around="true"
                :autoplay="5000"
            >
                <Slide v-for="slot in slots" :key="slot._uid">
                    <Slot :layout-slot="slot" />
                </Slide>

                <template #addons="{ slidesCount }">
                    <Pagination v-if="slidesCount > 1" />
                </template>
            </Carousel>
        </LazyClientOnly>
    </div>
</template>

<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css';

import type { ICoreLayout, ICoreLayoutSlot } from '~ui/types/components/CoreLayout';
import { EHideOnType } from '~ui/types/enums/ComponentStyle';
import Slot from '~ui/components/CoreLayout/Slot.vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

const { blok } = defineProps<{ blok: ICoreLayout }>();
const carouselRef: any = ref(null);
const slots = computed(() => blok.slots.filter((slot: ICoreLayoutSlot) => slot.hideOn !== EHideOnType.Mobile));
</script>

<style scoped lang="postcss"></style>
