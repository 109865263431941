<template>
    <SbFooter id="aprFooter" :blok="blok" accordion-icon="arrow-up-s-line" accordion-open-icon="arrow-down-s-line" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss">
#aprFooter {
    @apply bg-text-primary-asphalt text-sm px-4 pt-6 lg:pt-2 lg:pb-0;

    .footer-accordions {
        @apply sm:hidden;
    }

    .iconify {
        @apply relative top-[2px];
    }

    .accordion {
        .accordion-header {
            @apply flex items-center pb-6 text-text-titanium;
        }
        .accordion-title {
            @apply mr-2 text-base font-semibold;
        }
        .accordion-body {
            @apply pb-1;

            li {
                @apply pb-6;
            }
            a {
                @apply text-base text-text-titanium opacity-60 block font-semibold;
            }
        }
    }

    .links {
        @apply md:grid sm:pt-8 lg:grid-cols-4 lg:gap-8 pb-6 mb-6 sm:border-b border-solid items-start;
        border-color: rgba(251, 252, 249, 0.8);
    }
    .links-lists-container {
        @apply hidden sm:grid sm:grid-cols-3 sm:text-left lg:text-left mb-6 lg:mb-0;
        grid-column: 1 / span 3;
        .links-item {
            @apply mb-1;
            a {
                @apply text-text-titanium text-base font-normal opacity-60 hover:underline hover:opacity-100;
            }
        }
    }
    .links-col-title,
    .socials .section-label {
        @apply mb-5 text-text-titanium text-base block;
    }

    .socials {
        @apply justify-start items-center flex sm:flex flex-wrap gap-2 -ml-1;
        .section-label {
            @apply w-full pl-1;
        }
    }

    .socials-item {
        @apply px-1;
    }

    .socials-icon-wrap {
        @apply text-xl;
    }
    .socials-text {
        @apply hidden;
    }

    .socials-icon-wrap {
        @apply w-6 h-6 flex items-center justify-center rounded-full text-white bg-transparent text-2xl;
    }

    .socials-icon-image {
        @apply w-6 h-6;
    }

    .copyright {
        @apply flex flex-col sm:flex-row px-0 sm:pb-10;
        .links-lists-container {
            @apply flex sm:justify-end grow;
        }
        .links-list {
            @apply flex flex-row gap-6;
            .links-item a {
                @apply text-xs sm:text-base;
            }
        }
        .address {
            @apply block;
        }
    }

    .copyright-content {
        @apply text-text-titanium text-xs sm:text-sm opacity-60 mb-6 sm:mb-0;
    }
}
</style>
