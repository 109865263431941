<template>
    <UiOffersSection :blok="blok" button-type="advanced" class="apr-section" />
</template>

<script setup lang="ts">
import type { IOffersSection } from '~ui/types/components/OffersSection';
defineProps<{ blok: IOffersSection }>();
</script>

<style lang="postcss" scoped>
:deep(.offers-section) {
    &__title {
        @apply apr-section-title;
    }
    &__subtitle {
        @apply text-left text-text-primary-asphalt text-base mb-6 sm:mb-8;
    }
    &__wrapper {
        @apply flex flex-col md:flex-row gap-6 justify-center items-stretch;
    }
    &__item {
        @apply md:w-1/3;
    }
}
:deep(.offer-card) {
    @apply relative flex flex-col h-full;
    &__image {
        @apply block overflow-hidden rounded-lg h-[200px];
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
        img {
            @apply block;
        }
    }
    &__content {
        @apply flex flex-col items-start grow rounded-b overflow-hidden;
    }
    &__text {
        @apply text-base mb-3 mt-2 md:mt-4 md:mb-4 leading-6;
    }
    &__btn-wrapper {
        @apply mt-auto;
    }
}
</style>
