<template>
    <UiCountdownTimer
        v-editable="blok"
        class="sb-countdown-timer"
        data-test="sb-countdown-timer"
        :target-date="blok.targetDate"
        :timezone="blok.timezone"
        :blok="blok"
    />
</template>

<script setup lang="ts">
import { ICountdownTimerComponent } from '~ui/types/components/Countdown/CountdownTimer';

defineProps<{ blok: ICountdownTimerComponent }>();
</script>

<style scoped lang="postcss"></style>
