<template>
    <form id="sbHotelForm" class="form" @submit.prevent="submitForm()">
        <LazyUiSearchboxComponentsDestination
            name="airport"
            :title="destinationTitle"
            :tooltip="destinationTooltip"
            :placeholder="destinationPlaceholder"
            :value="formData.destination"
            :validation="v$.destination"
            @update:value="(value: any) => updateDestination(value)"
        />
        <LazyUiSearchboxComponentsSleep
            name="sleep"
            :title="tabs.sleepingLabel || 'When do you want to sleep at the hotel?'"
            :tooltip="tabs.sleepingTooltip || 'Select when you plan to sleep at the hotel'"
            :placeholder="tabs.sleepingPlaceholder || 'Choose which night to sleep'"
            :options="sleepOptions"
            :value="formData.package"
            :validation="v$.package"
            @update:value="(value: any) => updateSleep(value)"
        />
        <LazyUiSearchboxComponentsDates
            :package-info="packageOption"
            :min-date="new Date()"
            :value="formData.dates"
            :validation="v$.dates"
            :allow-single-range="true"
            :allow-same-date="false"
            :date-range="true"
            @update:value="(value: any) => updateDates(value)"
        />
        <LazyUiSearchboxComponentsGuests
            name="guests"
            :title="tabs.guestLabel || 'Guests'"
            :tooltip="tabs.guestTooltip || 'How many rooms and guests?'"
            :value="formData.guests"
            :validation="v$.guests"
            @update:value="(value: any) => updateGuestsAndRooms(value)"
        />
        <AdvancedBtn
            v-if="apr"
            component-variant="primary"
            component-type="button"
            button-type="submit"
            :with-arrow="true"
            :is-loading="loading"
            >Find your space</AdvancedBtn
        >
        <div v-else class="input-group-container submit">
            <span class="title">Submit</span>
            <button type="submit">
                <template v-if="!loading">Search <span class="fullname">Packages</span></template>
                <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
            </button>
        </div>
    </form>
</template>
<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import type { ISearchboxTab, ISleepPackageOptions } from '~ui/types';
import type { IDateOptions, ISearchFormData, ISearchTrip } from '~ui/types/components/Searchbox';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';

const { tabs, loading } = defineProps<{
    tabs: ISearchboxTab;
    loading: boolean;
    allowSameDate?: boolean;
    apr?: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: ISearchTrip): void;
}>();
const { t } = useI18n();

// Constant variable for access to search store
const searchStore = useSearchStore();

const destinationTitle = computed(() => {
    if (tabs.departureLabel && tabs.cruiseportDepartureLabel) {
        return searchStore.isCruiseport ? tabs.cruiseportDepartureLabel : tabs.departureLabel;
    } else {
        return `Departure ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
    }
});

const destinationTooltip = computed(() => {
    if (tabs.departureToolTip && tabs.cruiseportDepartureToolTip) {
        return searchStore.isCruiseport ? tabs.cruiseportDepartureToolTip : tabs.departureToolTip;
    } else {
        return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
    }
});

const destinationPlaceholder = computed(() => {
    if (tabs.departurePlaceholder && tabs.cruiseportDeparturePlaceholder) {
        return searchStore.isCruiseport ? tabs.cruiseportDeparturePlaceholder : tabs.departurePlaceholder;
    } else {
        return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
    }
});

const formData: Ref<ISearchFormData> = ref({
    destination: searchStore.destination,
    dates: searchStore.dates,
    guests: searchStore.guests,
    package: searchStore.package,
});

const updateDestination = (val: any) => {
    formData.value.destination = val;
    searchStore.update({ destination: val });
};

const updateSleep = (val: any) => {
    formData.value.package = val;
};

const updateDates = (val: any) => {
    formData.value.dates = val;
};

const updateGuestsAndRooms = (val: any) => {
    formData.value.guests = val;
};

const rules = computed(() => {
    return {
        destination: {
            fullname: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
            required: helpers.withMessage('Please enter destination', required),
            minLength: minLength(3),
        },
        package: {
            id: {
                required: helpers.withMessage('Select sleep option', required),
            },
        },
        dates: {
            start: {
                required: helpers.withMessage('Select Date', required),
            },
            end: {
                required: helpers.withMessage('Select Date', required),
            },
        },
        guests: {
            required: helpers.withMessage('Please select number of guests & rooms', required),
        },
    };
});

const v$ = useVuelidate(rules, formData);

//TODO: Are we use this function in this component?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getCurrentDate() {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
}

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        searchStore.update({ destination: formData.value.destination });
        searchStore.update({ package: formData.value.package });
        searchStore.update({ dates: formData.value.dates });
        searchStore.update({ guests: formData.value.guests });

        const destination: any = searchStore.destination;

        const query = {
            airport: destination.airport_code,
            destination,
            package: formData.value.package.id,
            date1: new Date(formData.value.dates.start).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            date2: new Date(formData.value.dates.end).toLocaleString('en-CA', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            }),
            guests: formData.value.guests.guests,
            rooms: formData.value.guests.rooms,
        };

        // Example JSON
        // {
        //     "airport": "LAX",
        //     "destination": {
        //         "type": "airport",
        //         "fullname": "Los Angeles (LAX)",
        //         "location": {
        //             "latitude": "33.942536",
        //             "longitude": "-118.40807"
        //         },
        //         "airport_code": "LAX"
        //     },
        //     "date1": "Nov 14, 2023",
        //     "date2": "Nov 16, 2023",
        //     "guests": 2,
        //     "rooms": 1,
        //     "package": "PFS"
        // }
        emit('submitSearch', query);
    }
};

const sleepOptions: ISleepPackageOptions = {
    PSF: {
        id: 'PSF',
        name: t('searchBox.sleepOptions.PSF.name'),
        additional: t('searchBox.sleepOptions.PSF.additional'),
    },
    PFS: {
        id: 'PFS',
        name: t('searchBox.sleepOptions.PFS.name'),
        additional: t('searchBox.sleepOptions.PFS.additional'),
    },
    PSFS: {
        id: 'PSFS',
        name: t('searchBox.sleepOptions.PSFS.name'),
        additional: t('searchBox.sleepOptions.PSFS.additional'),
    },
};

const dateOptions: IDateOptions = {
    PSF: {
        start_date: {
            title: t('searchBox.datePicker.PSF.start_date.title'),
            tooltip: t('searchBox.datePicker.PSF.start_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
        end_date: {
            title: t('searchBox.datePicker.PSF.end_date.title'),
            tooltip: t('searchBox.datePicker.PSF.end_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
    },
    PFS: {
        start_date: {
            title: t('searchBox.datePicker.PFS.start_date.title'),
            tooltip: t('searchBox.datePicker.PFS.start_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
        end_date: {
            title: t('searchBox.datePicker.PFS.end_date.title'),
            tooltip: t('searchBox.datePicker.PFS.end_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
    },
    PSFS: {
        start_date: {
            title: t('searchBox.datePicker.PSFS.start_date.title'),
            tooltip: t('searchBox.datePicker.PSFS.start_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
        end_date: {
            title: t('searchBox.datePicker.PSFS.end_date.title'),
            tooltip: t('searchBox.datePicker.PSFS.end_date.tooltip'),
            placeholder: t('searchBox.datePicker.common.placeholder'),
        },
    },
};

const packageOption = computed(() => {
    const packageId = formData.value.package.id;
    if (packageId && dateOptions[packageId]) {
        return dateOptions[packageId];
    } else {
        return dateOptions['PSF'];
    }
});
</script>
