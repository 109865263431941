<template>
    <UiLinkList class="link-list" :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss" scoped>
.link-list {
    :deep(.wrapper) {
        @apply mx-0 md:mx-4 xl:mx-0 max-w-none;
    }

    :deep(.links) {
        @apply p-0 gap-x-6;
        a {
            @apply text-[0.9rem] text-[#34b4f5] pr-0;
        }
        .link-wrapper {
            @apply pr-0;
        }
    }

    /* :deep(.section-title) {
        @apply md:hidden lg:text-2xl lg:leading-7 font-bold uppercase;
    } */
    :deep(.dt-accordion-section-title) {
        @apply lg:text-2xl lg:leading-7 font-bold uppercase mb-6;
    }
    :deep(.section-description) {
        @apply hidden px-4 text-sm font-normal mt-4 mb-8 mx-0;
    }

    :deep(.section-title) {
        @apply hidden;
    }

    :deep(.main-link-wrapper) {
        & > div {
            @apply flex flex-col gap-12;
        }

        hr {
            @apply my-4 border-t-[rgba(0,0,0,0.1)] border-0 border-t border-solid max-md:hidden;
        }

        .link-section-title {
            @apply lg:!text-2xl lg:!leading-7 !font-bold uppercase !pl-0 !mt-0;
        }

        .link-wrapper {
            @apply py-0.5 leading-5;
            a {
                @apply text-[0.85rem] text-link hover:underline;
            }
        }
    }

    :deep(.accordion-wrapper) {
        @apply p-4;
    }

    :deep(.accordion) {
        @apply flex flex-col bg-white rounded mb-3;

        &.active {
            .accordion-title {
                @apply text-link;
            }
            .accordion-icon {
                @apply text-link;
            }
        }

        .accordion-background > .wrapper {
            @apply relative h-[120px] flex justify-center items-center py-4 px-6 text-center m-0;
            img {
                @apply rounded-lg absolute inset-0 h-full w-full;
            }
            span {
                @apply relative z-10 text-xl font-bold text-text-titanium uppercase;
            }
        }

        .accordion-header {
            @apply order-2 justify-center px-0;
        }

        .accordion-title {
            @apply no-underline text-sm font-semibold pr-2 text-link;
        }
        .accordion-icon {
            @apply text-link;
        }
        .accordion-body {
            @apply px-0;
            ul {
                @apply flex flex-col;
            }
            li {
                @apply text-link;
            }
            ul li a {
                @apply text-link text-sm;
            }
        }
    }
    :deep(.desktop-accordion-wrapper) {
        @apply hidden md:block;
        .accordion-header {
            @apply py-4 flex font-semibold text-link leading-tight cursor-pointer items-center;

            .accordion-title {
                @apply leading-[1.2];
            }
        }
        .accordion {
            + .accordion {
                @apply mt-12;
            }
            &.active {
                .accordion-title,
                .accordion-icon {
                    @apply text-link;
                }
                .accordion-icon {
                    @apply rotate-180;
                }
            }
            &.is-hidden-toggler {
                .accordion-header {
                    @apply hidden;
                }
            }
        }

        .accordion-body {
            ul {
                @apply block;
                column-gap: 32px;

                &.grid-column-1 {
                    @apply columns-1;
                }

                &.grid-column-2 {
                    @apply columns-2;
                }

                &.grid-column-3 {
                    @apply columns-3;
                }

                &.grid-column-4 {
                    @apply columns-4;
                }
            }

            li {
                @apply pb-1 leading-[1.313rem] block;

                a {
                    @apply text-sm font-normal hover:underline;
                }
            }
        }

        .accordion-icon {
            @apply transition-transform duration-[0.3s] ease-[ease];
        }

        &.active {
            .accordion-icon {
                @apply -rotate-180;
            }
        }
    }
}
</style>
