<template>
    <UiCoreLayout v-editable="blok" class="sb-core-layout" data-test="sb-core-layout" :blok="blok" />
</template>

<script setup lang="ts">
import type { ICoreLayout } from '~ui/types/components/CoreLayout';

defineProps<{ blok: ICoreLayout }>();
</script>

<style scoped lang="postcss">
:deep(.core-layout-mobile-carousel) {
    .carousel__slide {
        @apply px-3;
        & > * {
            @apply w-full;
        }
    }
    .carousel__next,
    .carousel__prev {
        @apply h-10 w-10 text-lg rounded-full bg-white transition-all mx-0 sm:top-[200px];
        @apply xl:w-11 xl:h-11;

        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
    }
    .carousel__next {
        @apply right-0 sm:-right-2;
    }
    .carousel__prev {
        @apply left-0 sm:-left-2;
    }
    .carousel__next--disabled,
    .carousel__prev--disabled {
        @apply opacity-0 pointer-events-none;
    }
    .carousel__pagination {
        @apply flex mt-6;
    }
    .carousel__pagination-button {
        &::after {
            @apply w-2 h-2 rounded-full bg-brand-secondary-500 opacity-40;
        }
    }
    .carousel__pagination-button--active,
    .carousel__pagination-button:hover {
        &::after {
            @apply opacity-100 scale-125;
        }
    }
}
</style>
