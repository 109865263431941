<template>
    <div class="countdown-timer" :style="timerColorsStyle">
        <div class="days">
            <div class="group">
                <div v-for="(item, index) in splitDays" :key="`day-${item}-${index}`" class="digit">{{ item }}</div>
            </div>
            <div class="label">Days</div>
        </div>
        <div class="divider">:</div>
        <div class="hours">
            <div class="group">
                <div class="digit">{{ Math.floor(countdown.hours / 10) }}</div>
                <div class="digit">{{ Math.floor(countdown.hours % 10) }}</div>
            </div>
            <div class="label">Hours</div>
        </div>
        <div class="divider">:</div>
        <div class="minutes">
            <div class="group">
                <div class="digit">{{ Math.floor(countdown.minutes / 10) }}</div>
                <div class="digit">{{ Math.floor(countdown.minutes % 10) }}</div>
            </div>
            <div class="label">Minutes</div>
        </div>
        <div class="divider">:</div>
        <div class="seconds">
            <div class="group">
                <div class="digit">{{ Math.floor(countdown.seconds / 10) }}</div>
                <div class="digit">{{ Math.floor(countdown.seconds % 10) }}</div>
            </div>
            <div class="label">Seconds</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import type { CountdownTimer, ICountdownTimerComponent } from '~ui/types/components/Countdown/CountdownTimer';

// example: <UiCountdownTimer :targetDate="'10/11/2024 00:00:00'" />
const props = defineProps<{ targetDate: string; timezone: string; blok: ICountdownTimerComponent }>();
const injectedTargetDate = inject<number>('targetDate', '');
const timezone = props.timezone || '+00:00';
const targetDate = computed(() => (props.targetDate ? `${props.targetDate}:00${timezone}` : injectedTargetDate.value));
const countdown = ref<CountdownTimer>({ days: 0, hours: 0, minutes: 0, seconds: 0 });
const countDownDate = new Date(targetDate.value).getTime();
const splitDays = computed(() => {
    const days = countdown.value.days.toString().split('').map(Number);
    while (days.length < 2) {
        days.unshift(0);
    }

    return days;
});

const timerColorsStyle = computed(() => {
    return {
        '--timer-text-color': props.blok.timerTextColor?.color || null,
        '--timer-text-background-color': props.blok.timerTextBackgroundColor?.color || null,
        '--timer-labels-color': props.blok.timerLabelsColor?.color || null,
        '--timer-bg-opacity': props.blok?.timerOpacity || null,
    };
});

const calculateTime = () => {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    if (distance < 0) {
        countdown.value.days = 0;
        countdown.value.hours = 0;
        countdown.value.minutes = 0;
        countdown.value.seconds = 0;
    } else {
        // Time calculations for days, hours, minutes and seconds
        countdown.value.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        countdown.value.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        countdown.value.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        countdown.value.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    }
};
onMounted(() => {
    calculateTime();
    // Update the count down every 1 second
    const timer = setInterval(function () {
        calculateTime();
        if (
            countdown.value.days === 0 &&
            countdown.value.hours === 0 &&
            countdown.value.minutes === 0 &&
            countdown.value.seconds === 0
        ) {
            clearInterval(timer);
        }
    }, 1000);
});
</script>

<style lang="postcss" scoped>
.countdown-timer {
    --timer-text-color: theme('colors.white');
    --timer-text-background-color: theme('colors.orange.400');
    --timer-labels-color: theme('colors.slate.700');
    --timer-bg-opacity: 1;

    @apply flex p-4;
    .divider {
        @apply ml-1 mr-2 text-4xl text-[var(--timer-text-color)];
    }
    .group {
        @apply flex items-center;
        .digit {
            @apply w-8 h-12 mr-1 flex items-center justify-center bg-[var(--timer-text-background-color)] rounded text-4xl text-[var(--timer-text-color)] font-bold opacity-[var(--timer-bg-opacity)];

            @media (max-width: 410px) {
                @apply text-2xl w-6;
            }
        }
    }
    .label {
        @apply mt-2 mr-1 text-[var(--timer-labels-color)] text-center text-xs uppercase;
    }
}
</style>
