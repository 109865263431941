<template>
    <div class="apr-deals-card">
        <NuxtLink :to="getInternalLink(card.Link)" class="apr-deals-card__image">
            <NuxtImg
                class="image"
                :src="card.Image.filename"
                :alt="card.Image.alt"
                loading="lazy"
                :style="{
                    'object-fit': 'cover',
                    width: '100%',
                    height: '200px',
                }"
                sizes="sm:200vw md:150vw lg:100vw xl:1920px"
                densities="x1 x2"
                quality="50"
                provider="storyblok"
                format="webp"
            />
            <span v-if="card.ImageText" class="apr-deals-card__image-text">{{ card.ImageText }}</span>
        </NuxtLink>
        <div class="apr-deals-card__content">
            <h3 class="apr-deals-card__title">{{ card.Title }}</h3>
            <div class="apr-deals-card__text">{{ card.Text }}</div>
            <div class="apr-deals-card__bottom" :class="{ 'apr-deals-card__bottom--decorated': card.AdditionalInfo }">
                <div v-if="card.AdditionalInfo" class="apr-deals-card__additional">{{ card.AdditionalInfo }}</div>
                <UiAdvancedBtn
                    :to="getInternalLink(card.Link)"
                    component-variant="secondary"
                    component-type="link"
                    :with-arrow="true"
                    :target="card.Link.target"
                >
                    {{ card.ButtonText }}
                </UiAdvancedBtn>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IDealsCard } from '~/types/Deals';
defineProps<{ card: IDealsCard }>();
</script>

<style scoped lang="postcss">
.apr-deals-card {
    @apply relative flex flex-col h-full;
    &__image {
        @apply block overflow-hidden rounded-t-lg relative;
        &:before {
            content: '';
            @apply absolute top-0 left-0 w-full h-full rounded-t-lg z-10;
            background-color: rgba(0, 0, 0, 0.2);
        }
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
        img {
            @apply block transition-transform;
        }
        &-text {
            @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-3xl lg:text-[2.5rem] font-bold uppercase z-20 whitespace-nowrap;
        }
    }
    &__content {
        @apply flex flex-col items-start grow rounded-b-lg overflow-hidden border border-solid border-neutral-soft-grey shadow-[0_8px_16px_0_rgba(40,70,74,0.04)] text-left;
    }
    &__title {
        @apply font-black text-xl text-text-primary-asphalt mb-2 pt-6 px-4 lg:px-6;
    }
    &__text {
        @apply text-base mb-4 lg:mb-5 leading-6 px-4 lg:px-6;
    }
    &__bottom {
        @apply flex flex-col lg:flex-row items-start lg:items-center w-full mt-auto pb-6 px-4 lg:px-6;
        &--decorated {
            @apply pt-4 border-t border-dashed border-neutral-soft-grey;
        }
    }
    &__additional {
        @apply mr-auto text-xl lg:text-2xl leading-7 text-text-primary-asphalt mb-2 lg:mb-0;
    }
    &__deal-btn {
        @apply text-tertiary-350 text-center mt-auto py-2 text-lg font-bold;
    }
}
</style>
