export function snakeToPascal(string: string) {
    return string
        .split('/')
        .map((snake) =>
            snake
                .split('_')
                .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
                .join('')
        )
        .join('/');
}

export function getLinkByProductType(entity: any, productType: string) {
    if (productType === '/') return `/${entity.content.code ? entity.content?.code?.toLowerCase() : ''}`;

    return `/${entity.content.code ? entity.content?.code?.toLowerCase() : null}/${productType}`;
}

export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function median(values: number[]): number {
    if (values.length === 0) {
        throw new Error('Helper: Median - Input array is empty');
    }

    // Sorting values, preventing original array
    // from being mutated.
    values = [...values].sort((a, b) => a - b);

    const half = Math.floor(values.length / 2);

    return values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2;
}
export function formatDate(dateString: Date, format: string) {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dayOfWeek = dayNames[date.getDay()];
    const monthOfYear = monthNames[date.getMonth()];
    switch (format) {
        case 'yyyy-mm-dd':
            return `${yyyy}-${mm}-${dd}`;
        case 'day, dd/mm/yy': {
            const yy = String(yyyy).slice(-2);
            return `${dayOfWeek}, ${dd}/${mm}/${yy}`;
        }
        case 'day, dd/mm/yyyy': {
            return `${dayOfWeek}, ${dd}/${mm}/${yyyy}`;
        }
        case 'day, mmm dd': {
            return `${dayOfWeek}, ${monthOfYear} ${dd}`;
        }
        default:
            throw new Error('Unsupported format');
    }
}

export function getDaysBetweenDates(date1: Date, date2: Date): number {
    const dayInMilliseconds = 24 * 60 * 60 * 1000;
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    return Math.round(differenceInTime / dayInMilliseconds);
}

export const getDateDaysAhead = (currentDate: Date, days: number) => {
    currentDate.setDate(currentDate.getDate() + days);
    return currentDate;
};
