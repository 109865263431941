<template>
    <section v-if="blok" v-editable="blok" class="sbHero" :class="blok.CustomClass || ''">
        <NuxtImg
            v-if="blok.Image"
            :src="blok.Image.filename"
            :alt="blok.Image.alt"
            :style="{ 'object-position': blok.ImageOffset ?? 'center' }"
            provider="storyblok"
            sizes="100vw sm:200vw md:100vw lg:100vw"
            format="webp"
            class="hero-image"
            quality="75"
            densities="x1"
            preload
        />

        <div class="bg-overlay" :style="overlayStyles" />

        <div class="hero-container container">
            <div class="titles w-full px-4" :class="blok.Searchbox && blok.Searchbox[0] ? 'hidden md:block' : ''">
                <template v-for="title in blok.Titles" :key="title._uid">
                    <div
                        v-if="selectedTab === title?.Type || blok.Titles.length === 1"
                        :class="`text-${title.TitlePosition ?? 'center'} ${title.Style ?? ''}`"
                    >
                        <h1 v-show="!isMobileView">{{ getUtmTerm(title.Title) }}</h1>
                        <h2 v-show="!isMobileView" v-html="title.Subtitle"></h2>
                        <a
                            v-if="title.link_title"
                            class="block text-white mt-4"
                            href="#"
                            @click="openVideoModal(title.video_url.url, title.video_control, title.autoplay)"
                        >
                            <NuxtIcon :name="`ri:${title.link_icon}`" class="mr-2" />
                            {{ title.link_title }}
                        </a>
                    </div>
                </template>
            </div>
            <div v-if="blok.Searchbox && blok.Searchbox[0]" class="searchbox-wrapper">
                <Component
                    :is="searchbox.component"
                    v-for="searchbox in blok.Searchbox"
                    :key="searchbox._uid"
                    :blok="searchbox"
                    :titles="blok.Titles"
                />
            </div>
            <div v-if="blok.Steps.length" class="searchbox-steps">
                <div v-for="step in blok.Steps" :key="step._uid">
                    {{ step.title }}
                </div>
            </div>
        </div>
        <LazyUiModalsModal v-if="video.link" :is-open="showVideoModal" @modal-closed="onCloseModal()">
            <template v-if="video.link" #content>
                <iframe
                    :src="`${video.link}&controls=${Number(video.control)}&autoplay=${Number(video.autoplay)}`"
                    title="YouTube video player"
                    frameborder="0"
                    :allow="`accelerometer; ${video.autoplay ? 'autoplay' : ''}; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share`"
                    allowfullscreen
                ></iframe>
            </template>
        </LazyUiModalsModal>
    </section>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens';
const { isMobileView } = useIsMobileView(screens.sm);
// @ts-expect-error - Dynamically targeting route for search in _base project
import { useSearchStore } from '@/../_base/store/search';

const route: any = useRoute();
const getUtmTerm = (title) => {
    return route.query.utm_term ?? title;
};

const showVideoModal = ref(false);
const video = ref({
    link: '',
    control: false,
    autoplay: false,
});

const searchStore = useSearchStore();

const props: any = defineProps({ blok: { type: Object }, height: { type: Number } });
const selectedTab = useState('activeTab', () => {
    if (props.blok.Searchbox?.[0]?.DefaultTab && props.blok.Searchbox?.[0]?.DefaultTab != 'None') {
        return props.blok.Searchbox?.[0].DefaultTab;
    } else {
        return props.blok.Searchbox?.[0]?.Tabs?.[0]?.Type;
    }
});

if (props.blok.Searchbox?.[0]) {
    searchStore.updateActiveTab(selectedTab.value);
}

watch(searchStore, (store) => {
    if (selectedTab.value && store.activeTab != selectedTab?.value) {
        selectedTab.value = searchStore.activeTab;
    }
});

const openVideoModal = (videoLink: string, videoControl: boolean, autoplay: boolean) => {
    showVideoModal.value = true;
    video.value.link = videoLink;
    video.value.control = videoControl;
    video.value.autoplay = autoplay;
};

const onCloseModal = () => {
    showVideoModal.value = false;
    video.value.link = '';
};

const overlayStyles = computed(() => ({
    background: `linear-gradient(${props.blok.OverlayPrimaryColour?.color ? props.blok.OverlayPrimaryColour?.color : 'transparent'}, ${props.blok.OverlaySecondaryColour?.color ? props.blok.OverlaySecondaryColour?.color : 'transparent'})`,
    opacity: props.blok.OverlayOpacity ? props.blok.OverlayOpacity : props.blok.OverlayPrimaryColour?.color ? 1 : 0.5,
}));
</script>

<style lang="postcss" scoped>
.sbHero {
    @apply relative md:py-16 flex items-center justify-center bg-center bg-cover bg-gray-400;

    .bg-overlay {
        @apply absolute top-0 right-0 bottom-0 left-0 opacity-50 z-[1];
    }

    .hero-image {
        @apply absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover z-[0];
    }

    .hero-container {
        @apply relative z-[2] flex flex-col items-center justify-center mx-auto min-h-[300px] w-full;

        .titles {
            @apply text-center;
            h1,
            h2 {
                @apply text-gray-50;
            }
            h1 {
                @apply mb-2 text-4xl font-semibold;
            }
            h2 {
                @apply text-3xl font-normal;
            }
            a {
                @apply font-light;
                &:hover {
                    @apply underline;
                }
            }
        }

        .searchbox-wrapper {
            @apply md:mt-12 w-full;
        }

        .searchbox-steps {
            @apply hidden md:flex relative items-center leading-[34px] text-base font-semibold text-[#f8f9fa] w-full flex-wrap p-2 mb-4 md:justify-end justify-start md:mt-4;

            & > div {
                @apply relative flex items-center leading-[34px] text-base font-semibold text-[#f8f9fa] px-2;

                &:before {
                    @apply content-[""] inline-block h-6 w-6 mr-1.5;
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z' fill='%23A5CD39'/%3E%3C/svg%3E");
                }
            }
        }
    }

    iframe {
        @apply w-[100vw] md:w-[90vw] max-w-[950px] h-[calc(100vw_*_0.5625)] max-h-[600px];
    }
}
</style>
