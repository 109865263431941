<template>
    <AprFeaturesSectionWrapper
        v-if="blok"
        :blok="blok"
        class="how-section"
        :class="{ 'how-section--with-image': blok.Image.filename }"
    >
        <template #items>
            <div class="how-section__content">
                <div v-if="blok.WithSteps" class="how-section__list">
                    <NumberedFeaturesList :blok="blok" type="HowItWorks" />
                </div>
                <template v-else>
                    <div class="apr-rich-text how-section__descr" v-html="renderRichText(blok.Description)"></div>
                    <div v-if="blok?.FeaturesList?.length" class="how-section__feature-items">
                        <DefinitionDescription v-for="item in blok.FeaturesList" :key="item._uid" :blok="item" />
                    </div>
                </template>
            </div>
        </template>
        <template #cta>
            <div v-if="blok.ButtonText && (blok.ButtonLink.url || blok.ButtonLink.cached_url)" class="how-section__btn">
                <UiAdvancedBtn
                    component-variant="primary"
                    component-type="link"
                    :with-arrow="true"
                    :to="getInternalLink(blok.ButtonLink)"
                    :target="blok.ButtonLink.target"
                >
                    {{ blok.ButtonText }}
                </UiAdvancedBtn>
            </div>
        </template>
        <template #image>
            <div
                v-if="blok.Image?.filename"
                class="how-section__bg"
                :class="{ 'how-section__bg--full': blok.ImagePosition === 'background' }"
            >
                <NuxtImg
                    v-if="blok?.Image?.filename"
                    :src="blok.Image.filename"
                    alt=""
                    loading="lazy"
                    provider="storyblok"
                    format="webp"
                />
            </div>
        </template>
    </AprFeaturesSectionWrapper>
</template>

<script setup lang="ts">
import AprFeaturesSectionWrapper from './AprFeaturesSectionWrapper.vue';
import DefinitionDescription from '../cards/DefinitionDescription.vue';
import type { IHowItWorks } from '~/types/HowItWorks';

defineProps<{ blok: IHowItWorks }>();
</script>

<style lang="postcss" scoped>
.how-section {
    &--with-image {
        .how-section__content,
        :deep(.apr-section-title),
        :deep(.apr-section-subtitle) {
            @apply xl:max-w-aprTextContainer;
        }
    }

    &__list {
        @apply grid md:grid-cols-3 gap-4 lg:gap-8 overflow-hidden;
    }

    &__feature-items {
        @apply flex flex-col gap-3 md:gap-8;
    }

    &__bg {
        @apply hidden absolute right-0 top-0 h-full xl:flex items-center justify-end;
        img {
            @apply relative right-0 top-0 h-auto max-w-[300px] max-h-full;
        }
        &--full {
            @apply block left-0 w-full rounded-lg md:rounded-xl overflow-hidden;
            img {
                @apply left-0 max-w-none w-full h-full object-cover;
            }
        }
    }

    &__content,
    &__btn {
        @apply relative z-10;
    }

    &__btn {
        @apply mt-6 lg:mt-8;
        & > * {
            @apply w-full sm:w-auto;
        }
    }
}
</style>
