export default {
    theme: {
        extend: {
            colors: {
                primary: {
                    DEFAULT: '#26a9e0',
                    50: '#e9f6fc',
                    100: '#d4eef9',
                    200: '#a8ddf3',
                    300: '#7dcbec',
                    350: '#78cbe4',
                    400: '#51bae6',
                    500: '#26a9e0',
                    600: '#1e87b3',
                    700: '#1b769d',
                    800: '#135570',
                    900: '#0b3343',
                },
                secondary: {
                    DEFAULT: '#45c990',
                    50: '#ecfaf4',
                    100: '#daf4e9',
                    200: '#b5e9d3',
                    300: '#8fdfbc',
                    400: '#6ad4a6',
                    500: '#45c990',
                    600: '#37a173',
                    700: '#297956',
                    800: '#1c503a',
                    900: '#0e281d',
                },
                tertiary: {
                    DEFAULT: '#f58531',
                    50: '#fef2ea',
                    100: '#fde6d5',
                    200: '#fbceac',
                    300: '#f9b583',
                    350: '#F98F43',
                    400: '#f79d5a',
                    500: '#f58531',
                    550: '#f8791e',
                    600: '#c46a27',
                    700: '#934f1d',
                    800: '#623513',
                    900: '#311a09',
                },
                light: '#f8f9fa',
                body: { DEFAULT: '#495057' },
                gray: {
                    50: '#f8f9fa',
                    800: '#343a40',
                },
                stars: {
                    900: '#EDBD40',
                    0: '#AEBFD1',
                },
                error: {
                    DEFAULT: '#FF3738',
                },
                pink: {
                    DEFAULT: '#e62571',
                    border: '#e93f82',
                    hover: '#c11659',
                    borderHR: '#b41658',
                },
                btn: {
                    DEFAULT: '#f98f43',
                    border: '#f97316',
                    hover: '#f8791e',
                    borderHover: '#F8791E',
                },
            },
        },
    },
};
