<template>
    <footer id="sbFooter">
        <div class="container footer-container mx-auto">
            <div v-if="accordionsData?.length" class="footer-accordions">
                <Accordion
                    v-for="(item, index) in accordionsData"
                    :key="item._uid"
                    :is-open="item.isOpen"
                    :label="item.Aria_Label"
                    :data-test="`footer-accordion-${index}`"
                    @toggle="handleToggle(item._uid)"
                >
                    <template #title>{{ item.Title }}</template>
                    <template v-if="accordionIcon" #icon>
                        <NuxtIcon :name="`ri:${accordionIcon}`" />
                    </template>
                    <template #body>
                        <ul>
                            <li v-for="link in item.Links" :key="link._uid">
                                <a
                                    v-if="link.ModalId"
                                    href=""
                                    @click.prevent="modalStore.toggleModal(link.ModalId, true)"
                                >
                                    {{ link.Text }}
                                </a>
                                <nuxt-link v-else :to="getInternalLink(link.Link)" :target="link.Target" external>{{
                                    link.Text
                                }}</nuxt-link>
                            </li>
                        </ul>
                    </template>
                </Accordion>
            </div>
            <div class="overview">
                <div v-if="blok.Overview" class="rich-text">
                    <rich-text :content="blok.Overview" />
                </div>
            </div>
            <div class="links">
                <div class="links-lists-container">
                    <span v-if="blok.LinkSectionsLabel" class="section-label">{{ blok.LinkSectionsLabel }}</span>
                    <div
                        v-for="(linkSection, linkSectionIndex) in blok.Link_Sections"
                        :key="linkSection._uid"
                        class="links-col"
                        :data-test="`links-col-${linkSectionIndex}`"
                    >
                        <p class="links-col-title" data-test="links-col-title">{{ linkSection.Title }}</p>
                        <ul class="links-list">
                            <li v-for="(link, linkIndex) in linkSection.Links" :key="link._uid" class="links-item">
                                <a
                                    v-if="link.ModalId"
                                    href=""
                                    @click.prevent="modalStore.toggleModal(link.ModalId, true)"
                                >
                                    {{ link.Text }}
                                </a>
                                <nuxt-link
                                    v-else
                                    :to="getInternalLink(link.Link)"
                                    :target="link.Link?.target"
                                    external
                                    :data-test="`links-col-link-${linkIndex}`"
                                >
                                    {{ link.Text }}
                                </nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="socials">
                    <span v-if="blok.SocialsLabel" class="section-label">{{ blok.SocialsLabel }}</span>
                    <nuxt-link
                        v-for="socialItem in blok.Socials"
                        :key="socialItem._uid"
                        class="socials-item"
                        :to="getInternalLink(socialItem.Link)"
                        :target="socialItem.Target"
                        :aria-label="socialItem.Text"
                        external
                        data-test="socials-link"
                    >
                        <NuxtImg
                            v-if="socialItem?.CustomIcon?.filename"
                            :src="socialItem.CustomIcon.filename"
                            :alt="socialItem?.CustomIcon?.alt"
                            class="socials-icon-image"
                            loading="lazy"
                        />
                        <span v-else class="socials-icon-wrap">
                            <NuxtIcon
                                v-if="socialItem?.Icon?.icon?.includes('facebook')"
                                name="ri:facebook-fill"
                                class="socials-icon"
                            />
                            <NuxtIcon
                                v-else-if="socialItem?.Icon?.icon?.includes('twitter')"
                                name="ri:twitter-x-fill"
                                class="socials-icon"
                            />
                            <NuxtIcon
                                v-else-if="socialItem?.Icon?.icon?.includes('instagram')"
                                name="ri:instagram-line"
                                class="socials-icon"
                            />
                            <NuxtIcon
                                v-else-if="socialItem?.Icon?.icon?.includes('pinterest')"
                                name="ri:pinterest-line"
                                class="socials-icon"
                            />
                            <NuxtIcon
                                v-else-if="socialItem?.Icon?.icon?.includes('linkedin')"
                                name="ri:linkedin-fill"
                                class="socials-icon"
                            />
                            <NuxtImg
                                v-else-if="socialItem.CustomIcon?.filename"
                                :src="socialItem.CustomIcon?.filename"
                            />
                        </span>
                        <span class="socials-text">{{ socialItem.Text }}</span>
                    </nuxt-link>
                </div>
                <div class="icon-items">
                    <span v-if="blok.IconsLabel" class="section-label">{{ blok.IconsLabel }}</span>
                    <ul class="icon-items-container">
                        <li v-for="icon in blok.Icons" :key="icon._uid" class="icon-item">
                            <NuxtImg :src="icon.Image.filename" :alt="icon.Image.alt" loading="lazy" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="copyright">
                <p class="copyright-content">
                    ©
                    <span v-if="blok.Copyright_Start_Year" class="copyright-start-year">
                        {{ blok.Copyright_Start_Year }} -
                    </span>
                    <span data-test="copyright-current-year">{{ currentYear }}</span> {{ blok.Copyright }}
                    <span class="address" data-test="address">{{ blok.Address }}</span>
                </p>
                <div v-if="blok.Legal_Links" class="links-lists-container">
                    <ul class="links-list">
                        <li v-for="(link, linkIndex) in blok.Legal_Links[0].Links" :key="link._uid" class="links-item">
                            <a v-if="link.ModalId" href="" @click.prevent="modalStore.toggleModal(link.ModalId, true)">
                                {{ link.Text }}
                            </a>
                            <nuxt-link
                                v-else
                                :to="getInternalLink(link.Link)"
                                :target="link.Target"
                                external
                                :data-test="`links-col-link-${linkIndex}`"
                            >
                                {{ link.Text }}
                            </nuxt-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import Accordion from './Accordion.vue';
import { getInternalLink } from '../../../../apps/_base/utils/internalLinking';
import { useModalStore } from '../../../../apps/_base/store/modal';
import { IFaIcon, IRichText, ISimpleImage } from '../../../ui/types';
const modalStore = useModalStore();

interface IAccordionState {
    _uid: string;
    isOpen: boolean;
}

interface IFooterConfig {
    Link_Sections: ILinkSection[];
    Socials: any[];
    Icons: ISimpleImage[] | IFaIcon[];
    Copyright: string;
    Copyright_Start_Year: string;
    Address: string;
    Legal_Links?: ILinkSection[];
    Overview: IRichText;
    IconsLabel: string;
    SocialsLabel: string;
    LinkSectionsLabel: string;
}

interface ILinkSection {
    _uid: string;
    Title: string;
    Aria_Label: string;
    Links: any[];
}

const props = defineProps<{
    blok: IFooterConfig;
    accordionIcon?: string | string[];
    accordionOpenIcon?: string | string[];
}>();

const { render } = useStoryblokRichText({});
const richText = (content: any) => {
    return render(content.content);
};

// store accordions state(open/closed) in separate reactive variable
const accordionsState: IAccordionState[] = props.blok.Link_Sections.map((item) => ({ _uid: item._uid, isOpen: false }));
const accordions: Ref<IAccordionState[]> = ref(accordionsState);

// year to output in the footer
const currentYear: number = new Date().getFullYear();

// merge accordions state with storyblok data for further use in template
const accordionsData = computed(() => {
    return props.blok.Link_Sections.map((linkSectionObj) => ({
        ...linkSectionObj,
        ...accordions.value.find((accordion) => linkSectionObj._uid === accordion._uid),
    }));
});

// handle changing accordions state
// allow only one tab to be opened at a time
function handleToggle(toggleId: string) {
    accordions.value = accordions.value.map((accordion) => {
        accordion.isOpen = toggleId === accordion._uid ? !accordion.isOpen : false;
        return accordion;
    });
}
</script>

<style lang="postcss">
#sbFooter {
    .links-lists-container {
        @apply hidden sm:flex;
    }
    .links-col-title {
        @apply mb-2 text-base;
    }
    .links-list {
        @apply p-0 m-0;
    }
    .links-item {
        @apply leading-7;

        a {
            @apply hover:text-primary;
        }
    }

    .iconify {
        @apply w-5 h-5;
    }

    .copyright {
        @apply text-center text-xs sm:text-sm;
    }

    .footer-accordions {
        @apply sm:hidden;
    }
    .accordion {
        .accordion-header {
            @apply flex justify-between;
        }
    }
}
</style>
