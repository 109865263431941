<template>
    <div class="apr-dd-item">
        <div class="apr-dd-item__title">
            <NuxtImg
                v-if="blok?.Icon?.filename"
                class="apr-dd-item__icon"
                :src="blok.Icon.filename"
                :alt="blok.Icon.alt"
                loading="lazy"
                :style="{
                    'object-fit': 'contain',
                    width: '24px',
                    height: '24px',
                }"
                provider="storyblok"
                format="webp"
            />
            <span>{{ blok.Title }}</span>
        </div>
        <div class="apr-dd-item__descr">
            <div class="rich-text rich-text--big" v-html="renderRichText(blok.Description)"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IGlossaryItem } from '~/types/GlossarySection';

defineProps<{ blok: IGlossaryItem }>();
</script>

<style lang="postcss" scoped>
.apr-dd-item {
    @apply flex flex-col md:flex-row items-start gap-1 md:gap-6;
    &__title {
        @apply flex items-start gap-2 md:basis-44 shrink-0 text-base font-semibold text-text-primary-asphalt;
    }
    &__icon {
        @apply h-6 w-6 object-contain;
    }
    &__descr {
        :deep(p) {
            @apply m-0 text-base font-normal text-text-primary-asphalt;
        }
    }
}
</style>
