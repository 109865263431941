<template>
    <section class="container searchbox-container mx-auto">
        <div v-if="blok && blok.Tabs" class="tabs">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <a
                    href="#"
                    class="tab"
                    :class="{ [tab?.Type]: true, active: tab?.Type === activeTabState }"
                    @click.prevent="setSelectedTab(tab?.Type)"
                >
                    <div class="title">
                        <div class="icons">
                            <NuxtIcon v-if="tab.Icon1.icon" :name="`ri:${getIcon(tab.Icon1.icon)}`" />
                            <span v-if="tab.Icon1.icon && tab.Icon2.icon"> + </span>
                            <NuxtIcon v-if="tab.Icon2.icon" :name="`ri:${getIcon(tab.Icon2.icon)}`" />
                        </div>
                        <span class="hidden md:block">{{ parkingType(tab.Title) }}</span>

                        <span class="mobile-tab-title block md:hidden">{{ parkingType(tab.MobileTitle) }}</span>
                    </div>
                    <div class="subtitle">{{ parkingType(tab.Subtitle) }}</div>
                </a>
            </template>
        </div>

        <div class="mobile-titles">
            <template v-for="title in titles" :key="title._uid">
                <div v-if="activeTabState === title?.Type || titles.length === 1">
                    <div v-if="title?.Title" class="heading-1">{{ getUtmTerm(title.Title) }}</div>
                    <div v-if="title?.Subtitle" class="heading-2">{{ title.Subtitle }}</div>
                </div>
            </template>
        </div>

        <div class="forms">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <component
                    :is="FormComponents[tab?.Type]"
                    v-if="searchStore.activeTab == tab?.Type"
                    :tabs="tab"
                    :loading="loading"
                    :apr="apr"
                    @submit-search="submitForm"
                />
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
import HotelComponent from './Form/Hotel.vue';
import HotelWithParkingComponent from './Form/HotelWithParking.vue';
import ParkingComponent from './Form/Parking.vue';
import ShuttleComponent from './Form/Shuttle.vue';
import { useSearchStore } from '../../../../apps/_base/store/search';
import type { IHeroTitleItem, ISearchboxConfig } from '~ui/types';
import type { ISearchTrip, ISFSearch } from '~ui/types/components/Searchbox';

// Calendar Styles
import '@angelblanco/v-calendar/style.css';

const { blok, titles } = defineProps<{
    blok: ISearchboxConfig;
    titles: IHeroTitleItem[];
    loading: boolean;
    apr?: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitStoryblok', body: ISearchTrip | ISFSearch, sf?: boolean): void;
}>();

const route: any = useRoute();
const getUtmTerm = (title) => {
    return route.query.utm_term ?? title;
};

const searchStore: any = useSearchStore();

// Set Active Tab
const activeTabState: any = useState('activeTab', () => searchStore.activeTab ?? blok.DefaultTab);

const getIcon = (icon) => {
    if (icon.includes('bed')) return 'hotel-bed-fill';
    if (icon.includes('airport')) return 'map-pin-2-fill';
    if (icon.includes('cruiseport')) return 'ship-fill';
    if (icon.includes('city')) return 'city-fill';
    if (icon.includes('landmark')) return 'landmark-fill';
    if (icon.includes('address')) return 'location-dot-fill';
    if (icon.includes('train_station')) return 'train-car-fill';
    if (icon.includes('metro_station')) return 'subway-fill';
    if (icon.includes('district')) return 'city-fill';
    if (icon.includes('car')) return 'car-fill';
    if (icon.includes('bus')) return 'bus-fill';
};

// Update Tab when updated in Store
watch(searchStore, (store) => {
    if (activeTabState.value) activeTabState.value = store.activeTab;
});

// Set Default Tab
if (blok.DefaultTab !== 'None') {
    searchStore.updateActiveTab(blok.DefaultTab);
}

if (new Date(searchStore.dates?.start) < new Date()) {
    searchStore.update({ dates: { start: '', end: '' } });
}

const FormComponents: any = {
    Hotel: HotelComponent,
    HotelWithParking: HotelWithParkingComponent,
    Parking: ParkingComponent,
    Shuttle: ShuttleComponent,
};

const submitForm = (body: ISearchTrip | ISFSearch, sf?: boolean) => {
    emit('submitStoryblok', body, sf);
};

searchStore.updateIsCruisePort(blok.DefaultCruiseport);

onMounted(() => {
    if (process.client) {
        addEventListener('hashchange', () => {
            if (location.hash) {
                switchTabFromHash();
            }
        });
        if (location.hash) {
            switchTabFromHash();
        }
    }
});

function setSelectedTab(tab: string) {
    searchStore.updateActiveTab(tab);
}

function parkingType(text: string) {
    return searchStore.isCruiseport ? text.replace('airport', 'cruise port').replace('Airport', 'Cruise Port') : text;
}

function switchTabFromHash() {
    if (location.hash === '#search-apr') {
        setSelectedTab('Parking');
    }
    if (location.hash === '#search-psf') {
        setSelectedTab('HotelWithParking');
    }
    if (location.hash === '#search-hro') {
        setSelectedTab('Hotel');
    }
    if (location.hash.includes('#search')) {
        const noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }
}
</script>

<style lang="postcss" scoped>
.searchbox-container {
    @apply md:bg-gray-100 mx-auto;
}

.tabs {
    @apply flex bg-white md:bg-transparent;

    .tab {
        @apply flex-1 flex flex-col-reverse justify-center items-center;

        .icons {
            @apply text-center;
            span {
                @apply hidden md:inline-block;
            }
            .iconify {
                @apply !h-[1.125rem] !w-[1.125rem] mx-1;
            }
        }

        .title,
        .subtitle {
            @apply w-full py-3 text-center md:border-l-2 border-[#b1c2cf];
        }

        .title {
            @apply flex flex-col md:flex-row justify-center items-center md:bg-[#d1dce3] text-[#495866] text-sm leading-6;
        }
        .icons {
            @apply mr-2 text-lg leading-6;
        }
        .subtitle {
            @apply hidden md:block py-2 bg-[#557a8d] text-white text-xs;
        }

        &.active {
            .title {
                @apply md:bg-[#f5f9fc] text-[#18b2e1] border-b-4 md:border-b-0 border-[#18b2e1];
            }
            .subtitle {
                @apply bg-[#18b2e1];
            }
        }
    }

    .tab.active .title,
    .tab.active .subtitle,
    .tab.active + .tab .title,
    .tab.active + .tab .subtitle,
    .tab:first-child .title,
    .tab:first-child .subtitle {
        @apply border-transparent;
    }
}

.mobile-titles {
    @apply md:hidden my-4 text-center text-white;

    .heading-1 {
        @apply font-semibold;
    }

    .heading-2 {
        @apply font-thin;
    }
}

.forms {
    @apply transition-all;
}

.form {
    @apply flex xl:flex-nowrap flex-col xl:flex-row px-4 pb-4 md:px-2 md:py-2;

    &:deep(.input-group-container.guests) {
        @apply xl:max-w-[190px] xl:min-w-[190px];
    }

    :deep(.input-group-container) {
        @apply relative mb-2 md:mb-0 md:p-1 w-full bg-white rounded md:bg-transparent;

        .input-group-title {
            @apply absolute md:static top-3 left-4 z-[1] md:w-full text-xs md:whitespace-nowrap pointer-events-none;

            &.has-icon {
                @apply left-9;
            }
        }

        .input-group {
            @apply relative;

            .tooltip {
                @apply absolute z-[6] left-1/2 right-1/2 -translate-x-1/2 right-auto top-[4.25rem] min-w-max px-4 py-2 bg-gray-500 rounded text-white text-xs text-center;
                @apply transition-opacity duration-500 opacity-0 pointer-events-none;
            }

            @media screen(md) {
                .tooltip-handle:hover + .tooltip {
                    @apply opacity-100;
                    &::before {
                        content: '';
                        @apply absolute z-[-1] left-1/2 right-1/2 -translate-x-1/2 -top-1 px-2 py-1 h-4 w-3 bg-gray-500 rotate-45;
                    }
                }
            }

            .input-icon-append,
            .input-icon-prepend {
                @apply absolute top-6 md:top-5 text-primary-500 pointer-events-none z-[1];
            }

            .hide-desktop {
                @apply md:hidden;
                & + input {
                    @apply pl-9 md:pl-4;
                }
            }

            .input-icon-prepend {
                @apply left-3;
                &:not(.hide-desktop) + input,
                &:not(.hide-desktop) + .input-icon-append + input {
                    @apply pl-9;
                }
                /*** Mobile Only ***/
                @media (max-width: theme('screens.md')) {
                    & + input,
                    & + .input-icon-append + input {
                        @apply pl-9;
                    }
                }
            }

            .input-icon-append {
                @apply right-3 md:right-3;
                &:not(.hide-desktop) + input {
                    @apply pr-7;
                }

                &[data-icon='sort-down'] {
                    @apply top-5 md:top-4;
                }
            }

            input {
                @apply h-16 md:h-14 w-full pt-4 md:pt-0 pl-4 pr-2 md:bg-white md:border border-gray-500 rounded font-normal hover:border-primary-500 hover:outline outline-[2px] hover:-outline-offset-2 outline-primary-500 text-ellipsis;

                &::placeholder {
                    @apply font-thin;
                }

                &[readonly] {
                    @apply cursor-pointer;
                }

                &.calendar-showing {
                    @apply border-primary-500 outline -outline-offset-2;
                }
            }

            select {
                @apply h-16 md:h-14 w-full pt-4 md:pt-0 pl-9 pr-2 md:bg-white md:border border-gray-500 rounded font-normal hover:border-primary-500 hover:outline outline-[2px] hover:-outline-offset-2 outline-primary-500 text-ellipsis;
            }

            &.error {
                input {
                    @apply border-2 !border-red-500;
                }
            }
            .error-tooltip {
                @apply hidden md:block absolute z-[6] left-0 right-1/2 right-auto top-[4.25rem] px-4 py-2 bg-red-500 rounded text-white text-xs whitespace-nowrap;
                &::before {
                    content: '';
                    @apply absolute z-[-1] left-6 right-1/2 -translate-x-1/2 -top-1 px-2 py-1 h-4 w-3 bg-red-500 rotate-45;
                }
                &.custom-date {
                    @apply inline;
                    white-space: break-spaces;
                    @media screen and (min-width: 767px) {
                        white-space: nowrap;
                    }
                }
            }

            .dropdown-list,
            .dropdown-select {
                @apply max-w-full min-w-full md:min-w-0 md:max-w-none absolute top-16 mt-2 md:mt-0 border border-gray-500 rounded bg-white shadow-md md:whitespace-nowrap z-[26];
            }

            .dropdown-list {
                &-item {
                    @apply flex py-4 px-4 border-b border-gray-300 cursor-pointer;
                    &.active {
                        @apply bg-gray-500;
                    }
                    &:hover {
                        @apply bg-gray-200;
                    }
                }
            }

            .dropdown-select {
                @apply py-2;
                &-item {
                    @apply flex py-2 px-4;
                    &-selectors {
                        @apply flex pl-4;
                        .button {
                            @apply mx-2 bg-none border-0 cursor-pointer text-primary-500 text-lg;
                            &:hover {
                                @apply text-primary-350;
                            }
                            &.disabled {
                                @apply text-gray-500;
                            }
                        }
                    }
                }
            }
        }
    }

    :deep(.submit) {
        @apply flex flex-col md:p-1 bg-transparent xl:w-[150px] min-w-[150px];
        span.title {
            @apply hidden md:inline-block md:invisible text-sm mb-1;
        }
        button {
            @apply flex items-center justify-center h-14 px-8 bg-tertiary-350 hover:bg-tertiary-550 text-xl md:text-lg font-semibold text-white md:uppercase rounded transition-all duration-300;
            span.fullname {
                @apply ml-1 inline-block md:hidden text-xl md:text-lg;
            }
        }
    }
}
</style>
