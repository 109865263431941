<template>
    <div class="flex slot" :class="slotClasses" :style="slotStyles">
        <template v-for="content in layoutSlot.contents" :key="content._uid">
            <Component :is="content.component" :blok="content" />
        </template>
    </div>
</template>
<script setup lang="ts">
import type { ICoreLayoutSlot } from '~ui/types/components/CoreLayout';
import { buildBackground } from '~ui/utils/styleBuilder';
import { EComponentDirections, EHideOnType } from '~ui/types/enums/ComponentStyle';

const { layoutSlot, hasCustomItemPerRow = false } = defineProps<{
    layoutSlot: ICoreLayoutSlot;
    hasCustomItemPerRow?: boolean;
}>();

const slotStyles = computed(() => ({
    ...buildBackground(layoutSlot.background?.[0]),
    gap: layoutSlot.gaps ? `${layoutSlot.gaps}px` : null,
    paddingTop: layoutSlot.paddingTop ? `${layoutSlot.paddingTop}px` : null,
    paddingRight: layoutSlot.paddingRight ? `${layoutSlot.paddingRight}px` : null,
    paddingLeft: layoutSlot.paddingLeft ? `${layoutSlot.paddingLeft}px` : null,
    paddingBottom: layoutSlot.paddingBottom ? `${layoutSlot.paddingBottom}px` : null,
    '--slot-column':
        layoutSlot.columnWidth && !hasCustomItemPerRow
            ? `span ${layoutSlot.columnWidth} / span ${layoutSlot.columnWidth}`
            : null,
    '--flex-direction': layoutSlot.direction || null,
    '--flex-mobile-direction': layoutSlot.mobileDirection || null,
    '--align-content':
        (layoutSlot.direction === EComponentDirections.COLUMN
            ? layoutSlot.contentAlignment
            : layoutSlot.verticalAlignment) || null,
    '--justify-content':
        (layoutSlot.direction === EComponentDirections.ROW
            ? layoutSlot.contentAlignment
            : layoutSlot.verticalAlignment) || null,
    '--align-mobile-content':
        layoutSlot.mobileDirection === EComponentDirections.COLUMN ? layoutSlot.mobileContentAlignment : null,
    '--justify-mobile-content':
        layoutSlot.mobileDirection === EComponentDirections.ROW ? layoutSlot.mobileContentAlignment : null,
}));

const slotClasses = computed(() => ({
    'hide-mobile': layoutSlot.hideOn === EHideOnType.Mobile,
    'hide-desktop': layoutSlot.hideOn === EHideOnType.Desktop,
}));
</script>

<style scoped lang="postcss">
.slot {
    @apply gap-4 col-auto md:col-[var(--slot-column)];
    flex-direction: var(--flex-direction);
    align-items: var(--align-content);
    justify-content: var(--justify-content);

    &.hide-desktop {
        @apply md:hidden;
    }

    &.hide-mobile {
        @apply max-md:hidden;
    }

    @media screen and (max-width: 768px) {
        flex-direction: var(--flex-mobile-direction);
        align-items: var(--align-mobile-content);
        justify-content: var(--justify-mobile-content);
    }
}
</style>
