<template>
    <button
        v-if="componentType === 'button'"
        :type="buttonType"
        :class="[`btn-brand btn-brand--${componentVariant}`, fullWidth && 'w-full']"
    >
        <slot></slot>
        <i v-if="withArrow" data-icon="true" class="apr-arrow-right" aria-hidden="true"></i>
        <span v-show="isLoading" class="btn-brand__loading-container">
            <span class="btn-brand__loading"></span>
        </span>
    </button>
    <nuxt-link
        v-else
        :to="to"
        :class="[`btn-brand btn-brand--${componentVariant}`, fullWidth && 'w-full']"
        :target="target ? target : null"
    >
        <slot></slot>
        <i v-if="withArrow" data-icon="true" class="apr-arrow-right" aria-hidden="true"></i>
        <span v-show="isLoading" class="btn-brand__loading-container">
            <span class="btn-brand__loading"></span>
        </span>
    </nuxt-link>
</template>

<script setup lang="ts">
import type { IAdvancedBtn } from '~ui/types/components/AdvancedBtn';
defineProps<IAdvancedBtn>();
</script>

<style lang="postcss">
.btn-brand {
    font-family: 'Expressway', sans-serif;
    @apply relative inline-flex justify-center items-center h-12 rounded-lg text-base font-semibold uppercase transition-colors duration-300;
    padding: 11.5px 24px 12.5px 24px;

    &--primary {
        @apply bg-[#b4e076] border-none text-[#0a475a];

        &:hover {
            background: #c3e691;
            text-decoration: none;
            color: #0a475a;
        }

        &:active {
            outline: 4px solid #b4e076;
        }

        &:disabled {
            pointer-events: none;
            cursor: default;
            color: #869195;
            background-color: e7e9ea;
            .btn-brand__loading-container {
                background-color: e7e9ea;
            }
        }
    }

    &--secondary,
    &--secondary-inverted {
        border: 2px solid currentColor;
    }

    &--secondary {
        color: #0a475a;
        &:hover {
            color: #3b6c7b;
        }
    }

    &--secondary-inverted {
        color: #fbfcf9;
        &:hover {
            color: #e7e9ea;
        }
    }

    &--tertiary {
        color: #0a475a;
        border: none;
        &:hover {
            color: #3b6c7b;
            text-decoration: none;
        }
    }

    &:hover {
        .apr-arrow-right:before {
            animation: arrowRight 0.5s ease alternate forwards;
            animation-delay: 0.31s;
        }
    }

    &__loading-container {
        @apply absolute top-0 left-0 w-full h-full flex justify-center z-10 rounded-lg;
        background: #b4e076;
    }
    &__loading {
        margin-top: 6px;
    }
}

.apr-arrow-right {
    width: 12px;
    height: 15px;
    margin-top: 1px;
    margin-left: 10px;
    color: currentColor;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        background-color: currentColor;
        mask-repeat: no-repeat;
        mask-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='15' viewBox='0 0 12 15' fill='none'%3E%3Cpath d='M11.5 7.5L9.10718 9.83333L7.42291 11.4757L4.32154 14.5L1.92872 12.1667L5.01848 9.15372H0.5V5.85761H5.01848L1.92872 2.83333L4.32154 0.5L7.42291 3.52427L9.10718 5.16667L11.5 7.5Z' fill='%230A475A'/%3E%3C/svg%3E");
    }
}

@keyframes arrowRight {
    0%,
    52% {
        transform: translateX(-105%);
        opacity: 0;
    }

    2%,
    100% {
        transform: translateX(0);
        opacity: 1;
    }

    50% {
        transform: translateX(105%);
        opacity: 1;
    }

    51% {
        transform: translateX(105%);
        opacity: 0;
    }
}

.btn-brand__loading,
.btn-brand__loading:before,
.btn-brand__loading:after {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.btn-brand__loading {
    color: #fff;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.btn-brand__loading:before,
.btn-brand__loading:after {
    content: '';
    position: absolute;
    top: 0;
}

.btn-brand__loading:before {
    left: -3.5em;
    animation-delay: -0.32s;
}

.btn-brand__loading:after {
    left: 3.5em;
}

@keyframes bblFadInOut {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em #0a475a;
    }

    40% {
        box-shadow: 0 2.5em 0 0 #0a475a;
    }
}
</style>
