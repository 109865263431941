<template>
    <SbTrustpilot id="SbTrustpilot" :blok="blok" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss" scoped>
#SbTrustpilot {
    @apply flex justify-center h-full apr-section;

    :deep(.container) {
        @apply w-full;

        h3 {
            @apply apr-section-title text-text-primary-asphalt px-4 xl:px-0;

            & + .trustpilot {
                @apply mt-6 sm:mt-8;
            }

            /* &.mini {
                @apply text-lg md:text-2xl font-medium;
            } */
        }

        .trustpilot-subtitle {
            @apply text-left text-text-primary-asphalt text-base mb-6 sm:mb-8 px-4 xl:px-0;
        }

        .trustpilot {
            .trustpilot-widget {
                /* @apply h-[500px]; */
                @apply h-[220px];
                iframe {
                    @apply h-full;
                }
            }

            /* &.small {
                .trustpilot-widget {
                    @apply h-[250px];
                }
            } */
        }
    }
}
</style>
