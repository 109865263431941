export function useIsMobileView(mobileBreakpoint: string) {
    const isMobileView = ref(false);
    if (process.server) {
        const userAgent = useRequestHeaders(['user-agent'])?.['user-agent'] || '';
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        isMobileView.value = mobileRegex.test(userAgent);
    }

    function checkIfMobileView() {
        if (!process.server) {
            isMobileView.value = !window.matchMedia(`(min-width: ${mobileBreakpoint})`).matches;
        }
    }

    checkIfMobileView();
    onMounted(() => {
        window.addEventListener('resize', checkIfMobileView);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', checkIfMobileView);
    });

    return { isMobileView };
}
