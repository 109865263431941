import { defineStore } from 'pinia';
import { Loader } from '@googlemaps/js-api-loader';

export const useGoogleMapApi = defineStore('googleMapApi', {
    state: () => ({
        apiLoader: null,
    }),
    getters: {
        loader: (state): Loader => {
            if (!state.apiLoader) {
                state.apiLoader = new Loader({
                    apiKey: '',
                    version: '',
                    language: 'en',
                    libraries: ['maps'],
                });
            }

            return state.apiLoader;
        },
    },
});
