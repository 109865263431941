<template>
    <UiCoreCountdownSection
        v-editable="blok"
        class="sb-core-countdown-section"
        data-test="sb-core-countdown-section"
        :blok="blok"
    />
</template>

<script setup lang="ts">
import type { ICoreCountdownSection } from '~ui/types/components/Countdown/CoreCountdownSection';

defineProps<{ blok: ICoreCountdownSection }>();
</script>

<style scoped lang="postcss"></style>
