<template>
    <PartnersContainer v-if="blok.Partners" :blok="blok.Partners.content" />
</template>

<script setup lang="ts">
import PartnersContainer from '~/components/sections/partners/PartnersContainer.vue';
import type { IPartnersContainer } from '~/types/Partners';

const { blok } = defineProps<{ blok: IPartnersContainer }>();
</script>

<style lang="postcss" scoped></style>
