const getTypeFromPrediction = (types: string[]): string => {
    switch (true) {
        case types.includes('transit_station'):
        case types.includes('route'):
            return 'address';
        case types.includes('point_of_interest'):
        case types.includes('natural_feature'):
            return 'point of interest';
        case types.includes('establishment'):
            return 'place';

        case types.includes('locality'):
            return 'city';
        default:
            return 'address';
    }
};
export const fetchGooglePredictions = (apiService: any, apiSessionToken: any, searchQuery: string): Promise<any[]> => {
    return new Promise((resolve) => {
        apiService.getPlacePredictions(
            {
                // types: ['(cities)'],
                input: searchQuery,
                componentRestrictions: { country: 'us' },
                sessionToken: apiSessionToken,
            },
            function (predictions: any, status: any) {
                const items: Array<Object> = [];
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    predictions.forEach(function (prediction: any) {
                        items.push({
                            fullname: prediction.description,
                            place_id: prediction.place_id,
                            type: getTypeFromPrediction(prediction.types),
                            provider: 'google',
                        });
                    });
                    resolve(items);
                }
            }
        );
    });
};

export const fetchAlgolia = async (val: string, google: boolean = false, onlyAirports: boolean = true) => {
    const options = {
        hitsPerPage: 6,
        filters: '',
    };

    if (onlyAirports) {
        options.filters =
            '(type:airport OR type:cruiseport) AND active:true AND (hierarchy.country.code:US OR hierarchy.country.code:CA)';
    }

    //@ts-ignore
    const { data } = await useAsyncAlgoliaSearch({
        indexName: google ? useNuxtApp().$config?.public.ALGOLIA_SF_INDEX : useNuxtApp().$config?.public.ALGOLIA_INDEX,
        query: val,
        requestOptions: options,
    });

    return data.value;
};
