<template>
    <div class="tab-group container">
        <div class="tab-buttons">
            <div
                v-for="(button, index) in blok.TabGroup"
                :key="button.uid"
                class="tab-button"
                :class="{ active: selectedTab === index }"
                @click="selectTab(index)"
            >
                {{ button.title }}
            </div>
        </div>

        <div class="tab-content">
            <template v-for="link in blok.Links" :key="link.uid">
                <Transition :name="selectedTab === link.Tab - 1 ? 'fade' : ''">
                    <div v-if="selectedTab === link.Tab - 1" :key="link.uid">
                        <h5
                            :style="`
                                font-weight: ${link?.title_font_weight || 400};
                                ${!isMobileView ? `font-size: ${link?.title_font_size || 1.09375}rem` : ''};
                            `"
                        >
                            {{ link.Title }}
                        </h5>
                        <ul :style="`grid-template-columns: repeat(${blok.ColumnCount}, 1fr)`">
                            <li v-for="(entity, index) in link.entities" :key="'entity' + index" class="link-wrapper">
                                <nuxt-link :to="getLinkByProductType(entity, link.ProductType)" external>
                                    {{
                                        getAirportUrlName(entity.content.fullname) ??
                                        entity.content.city ??
                                        entity.content.city_name
                                    }}
                                    <span v-if="link.AppendText">{{ link.AppendText }}</span>
                                </nuxt-link>
                            </li>
                            <li v-for="subLink in link.Links" :key="subLink.uid" class="link-wrapper">
                                <nuxt-link :to="getInternalLink(subLink.Link)" external>{{ subLink.Text }}</nuxt-link>
                            </li>
                        </ul>
                    </div>
                </Transition>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useIsMobileView } from '~ui/composables/isMobileView';
import { getLinkByProductType } from '~ui/utils/helpers';

const { isMobileView } = useIsMobileView('768px');
const { blok } = defineProps<{ blok: any }>();

// initial selected tab group
const selectedTab = ref(0);

// remove airport word from entity fullname
const getAirportUrlName = (entityFullname: any) => entityFullname?.replace(/airport/gi, '');
// select tab
const selectTab = (tabNumber: any) => {
    selectedTab.value = tabNumber;
};
</script>

<style lang="postcss" scoped>
a {
    @apply text-[#18b2e1] pr-4;

    &:hover {
        @apply underline;
    }
}

.link-wrapper {
    @apply py-1;
}
.tab-group {
    @apply text-sm m-auto px-4;
    ul {
        @apply grid gap-x-[0.8rem];
    }
    h5 {
        @apply text-[1.09375rem];
    }

    .tab-buttons {
        @apply flex border-solid border-b-[#aebfd1] border-b;

        .tab-button {
            @apply flex-1 md:flex-none cursor-pointer font-normal text-gray-800 text-center mt-2 px-0 md:px-4 py-2 border-0;

            &.active {
                @apply text-primary border-b-[3px] md:border-b-[5px] border-b-primary border-solid;
            }
        }
    }
    @media (max-width: 768px) {
        ul {
            @apply grid grid-cols-[repeat(1,1fr)] !important;
        }
    }
}
h5 {
    @apply my-4;
}
</style>
