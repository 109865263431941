<template>
    <UiHero id="sbHero" v-editable="blok" :blok="blok" :height="1142" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style scoped lang="postcss">
#sbHero {
    @apply pt-4 sm:pt-20 xl:pt-32 pb-6 sm:pb-16 xl:pb-28 relative bg-no-repeat;
    background-position: 50% 70%;
    &.with-arrow {
        @media (min-width: theme('screens.sm')) {
            background-size: auto 170%;
            background-position: 100% 94%;
        }
        @media (min-width: theme('screens.xl')) {
            background-size: cover;
            background-position: 50% calc(48% - 9vw);
        }
    }

    :deep(.bg-overlay) {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
    :deep(.searchbox-wrapper) {
        @apply px-4 sm:mt-0 xl:p-0;
    }
    :deep(.hero-container) {
        @apply max-w-[76.5rem];
        .titles {
            @apply block xl:px-0;
            h1,
            h2 {
                @apply !block;
            }

            h1 {
                @apply text-base sm:text-2xl md:text-[2.5rem] md:leading-[3rem] mb-1 !font-bold !uppercase;
            }

            h2 {
                @apply text-base sm:text-xl mb-4 font-normal normal-case;
            }

            .style-1 {
                h1 {
                    @apply text-base sm:text-2xl md:text-[2.5rem] md:leading-[3rem] mb-1 !font-bold;
                }

                h2 {
                    @apply text-2xl sm:text-3xl md:text-[4rem] leading-7 md:leading-[4.5rem] md:tracking-wide mb-4 !font-bold !uppercase;
                }
            }

            .style-2 {
                h1 {
                    @apply text-base sm:text-2xl md:text-[2.5rem] md:leading-[3rem] mb-1 !font-bold;
                }

                h2 {
                    @apply text-base sm:text-xl mb-4 font-normal normal-case;
                }
            }

            .style-3 {
                h1 {
                    @apply text-2xl sm:text-3xl md:text-[4rem] leading-7 md:leading-[4.5rem] md:tracking-wide mb-4 !font-bold;
                }

                h2 {
                    @apply text-base sm:text-xl xl:text-xl mb-4 font-normal normal-case;
                }
            }
        }
    }
    :deep(.searchbox-steps) {
        @apply md:max-w-[725px] xl:max-w-full flex justify-start flex-wrap gap-x-5 gap-y-2 mt-0 pt-4 sm:pt-3 pb-0 mb-0 px-4 md:px-0 leading-6 relative;
        > div {
            @apply px-0;
            &:before {
                @apply h-4 w-4 mr-2 bg-cover;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_3077_3174)'%3E%3Cpath d='M11.802 9.578L14.68 12.46L12.456 14.686L12.45 14.68L12.448 14.682L9.568 11.8V15.998L6.432 16V11.8L3.55 14.682L1.326 12.454L4.2 9.578H0V6.438H4.2L1.326 3.558L3.55 1.332L6.432 4.216V0H9.566L9.568 4.216L12.448 1.332L12.45 1.334L12.456 1.328L14.68 3.554L11.802 6.438H15.998L16 9.576L11.802 9.578Z' fill='%23B4E076'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3077_3174'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }
        }
    }
}
</style>
