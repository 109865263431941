<template>
    <div class="apr-feature-item" :class="`apr-feature-item--${blok.Layout}`">
        <div class="apr-feature-item__image">
            <NuxtImg
                v-if="blok?.Image?.filename"
                :src="blok.Image.filename"
                :alt="blok.Image.alt"
                loading="lazy"
                :style="{
                    'object-fit': 'contain',
                }"
                provider="storyblok"
                format="webp"
            />
        </div>
        <div class="apr-feature-item__content">
            <div class="apr-feature-item__title">{{ blok.Title }}</div>
            <div class="apr-feature-item__text">{{ blok.Text }}</div>
            <nuxt-link
                v-if="blok.Link"
                :to="getInternalLink(blok.Link)"
                :target="blok.Link.target"
                class="apr-feature-item__link"
            >
                {{ blok.LinkText }}
            </nuxt-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IAprFeatureItem } from '~/types/BookWithUs';
defineProps<{ blok: IAprFeatureItem }>();
</script>

<style lang="postcss" scoped>
.apr-feature-item {
    @apply flex gap-3 md:gap-6 text-left text-current;
    &--column {
        @apply md:flex-col md:text-center;
    }
    &__image {
        @apply shrink-0;
        img {
            @apply h-[72px] w-[72px] md:h-[104px] md:w-[104px] object-contain inline-block;
        }
    }
    &__title {
        @apply text-base md:text-xl mb-1 font-bold;
    }
    &__text {
        @apply text-base font-normal mb-1;
    }
    &__link {
        @apply text-base font-semibold hover:underline;
    }
}
</style>
