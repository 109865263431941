<template>
    <section
        v-if="blok"
        class="apr-section apr-features-section"
        :class="[
            `apr-features-section--${blok.Layout}`,
            blok.BackgroundColor?.color && `apr-features-section--with-bg`,
        ]"
    >
        <div
            class="apr-features-section__wrapper"
            :style="{
                backgroundColor: blok.Layout === 'fullWidth' ? blok.BackgroundColor?.color : 'transparent',
                color: blok.TextColor?.color,
            }"
        >
            <div class="apr-features-section__container container">
                <div class="apr-features-section__inner" :style="{ backgroundColor: blok.BackgroundColor?.color }">
                    <h3 class="apr-section-title">{{ blok.Title }}</h3>
                    <p v-if="blok.Subtitle" class="apr-section-subtitle">
                        {{ blok.Subtitle }}
                    </p>
                    <slot name="items"></slot>
                    <slot name="cta"></slot>
                    <slot name="image"></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
defineProps<{ blok: any }>();
</script>

<style lang="postcss" scoped>
.apr-features-section {
    &__wrapper {
        .apr-features-section--card & {
            @apply mx-4 sm:mx-0;
        }
    }
    .container {
        @apply mx-auto sm:px-4 xl:px-0;
    }
    &__inner {
        @apply px-4 sm:px-0  sm:rounded-xl relative mx-auto;
        .apr-features-section--with-bg &,
        .apr-features-section--card & {
            @apply py-6 sm:py-10 xl:py-16;
        }
        .apr-features-section--card &,
        .apr-features-section--embedded & {
            @apply sm:px-12 xl:px-16;
        }
    }
    .apr-section-title,
    .apr-section-subtitle {
        @apply text-current relative z-10;
    }

    .apr-section-title + * {
        @apply mt-6 md:mt-8;
    }
    .apr-section-title + .apr-section-subtitle {
        @apply mt-0;
    }
}
</style>
