<template>
    <form class="form" @submit.prevent="submitForm()">
        <div class="grid grid-cols-2 xl:grid-cols-9 w-full">
            <div class="col-span-2 xl:col-span-4 order-1">
                <LazyUiSearchboxComponentsDestination
                    v-model:value="formData.pickUp"
                    name="pickup"
                    :title="t('forms.shuttles.pickupLocationTitle')"
                    :placeholder="t('forms.shuttles.pickupLocationPlaceholder')"
                    :google="true"
                    :only-airports="false"
                    :validation="v$.pickUp"
                />
            </div>
            <div class="col-span-2 xl:col-span-4 order-2">
                <LazyUiSearchboxComponentsDestination
                    v-model:value="formData.dropOff"
                    name="dropoff"
                    :title="t('forms.shuttles.dropOffTitle')"
                    :placeholder="t('forms.shuttles.dropOffPlaceholder')"
                    :google="true"
                    :only-airports="false"
                    :validation="v$.dropOff"
                    date-format="day, mmm dd"
                />
            </div>
            <div class="col-span-1 xl:col-span-1 order-3">
                <SimpleSelect
                    v-model:value="formData.passengers"
                    :title="t('forms.shuttles.passengerTitle')"
                    name="pass"
                    :options="createArrayOfObjects(50)"
                    prepend-icon="user-3-fill"
                    class="passengers-select"
                />
            </div>
            <div class="col-span-2 xl:col-span-3 order-5 xl:order-4">
                <LazyUiSearchboxComponentsDateTimePicker
                    v-model:value="formData.dateDep"
                    :label="depLabels"
                    name="departure_date"
                    :min-date="new Date()"
                    :date-picker-title="t('forms.shuttles.selectDepartureDateTitle')"
                    :validation="v$.dateDep"
                    :apr="apr"
                    date-format="day, mmm dd"
                    :time-increments="15"
                />
            </div>
            <div class="col-span-1 order-4 xl:order-5">
                <LazyUiSearchboxComponentsToggle
                    v-model:value="formData.returnTrip"
                    name="checkbox"
                    :label="t('forms.shuttles.bookReturnSwitcherLabel')"
                />
            </div>
            <div v-if="formData.returnTrip" class="col-span-2 xl:col-span-3 order-6">
                <LazyUiSearchboxComponentsDateTimePicker
                    v-model:value="formData.dateRep"
                    :label="repLabels"
                    name="arrival_date"
                    :min-date="formData.dateDep.date ? new Date(formData.dateDep.date) : new Date()"
                    :date-picker-title="t('forms.shuttles.selectArrivalDateTitle')"
                    :validation="v$.dateRep"
                    :apr="apr"
                    date-format="day, mmm dd"
                    :time-increments="15"
                />
            </div>
            <AdvancedBtn
                v-if="apr"
                class="col-span-2 xl:col-start-8 xl:col-end-10 order-7 shuttle-btn"
                component-variant="primary"
                component-type="button"
                button-type="submit"
                :with-arrow="true"
                :is-loading="loading"
                >{{ t('forms.shuttles.searchBtnText') }}
            </AdvancedBtn>
            <div v-else class="input-group-container submit col-span-2 xl:col-start-8 xl:col-end-10 order-7">
                <span class="title">{{ t('forms.shuttles.submitBtnText') }}</span>
                <button type="submit">
                    <template v-if="!loading">{{ t('forms.shuttles.searchBtnText') }}</template>
                    <NuxtIcon v-else name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
            <div class="col-span-2 xl:col-start-8 xl:col-end-10 order-last custom-label">
                <small>Powered By <a href="https://www.shuttlefinder.com/" rel="nofollow">Shuttlefinder</a></small>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import SimpleSelect from '~ui/components/Searchbox/Components/SimpleSelect.vue';
import AdvancedBtn from '~ui/components/AdvancedBtn.vue';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { ISFSearch, ISFSearchFormData } from '~ui/types/components/Searchbox';
import type { Ref } from 'vue';

defineProps<{ loading: boolean; apr?: boolean }>();
const { t } = useI18n();
const emit = defineEmits<{
    (e: 'submitSearch', query: ISFSearch, sf: boolean): void;
}>();

const formData: Ref<ISFSearchFormData> = ref({
    pickUp: null,
    dropOff: null,
    returnTrip: false,
    passengers: '1',
    dateDep: {
        date: '',
        time: '',
    },
    dateRep: {
        date: '',
        time: '',
    },
});

const createArrayOfObjects = (n: number) =>
    Array.from({ length: n }, (_, i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString(),
    }));

const rules = computed(() => {
    return {
        pickUp: {
            required: helpers.withMessage(t('forms.shuttles.errors.pickUpLocationRequired'), required),
        },
        dropOff: {
            required: helpers.withMessage(t('forms.shuttles.errors.dropOffLocationRequired'), required),
        },
        dateDep: {
            date: {
                required: helpers.withMessage(t('forms.shuttles.errors.departDateRequired'), required),
            },
            time: {
                required: helpers.withMessage(t('forms.shuttles.errors.departTimeRequired'), required),
            },
        },
        dateRep: formData.value.returnTrip
            ? {
                  date: {
                      required: helpers.withMessage(t('forms.shuttles.errors.returnDateRequired'), required),
                  },
                  time: {
                      required: helpers.withMessage(t('forms.shuttles.errors.returnTimeRequired'), required),
                  },
              }
            : {},
    };
});

const v$ = useVuelidate(rules, formData);

const tripType = computed(() => {
    let tripType = 'nonAirport';

    const locationType1 = formData.value.pickUp?.type;
    const locationType2 = formData.value.dropOff?.type;

    const isType1Airport = ['airport', 'cruiseport'].includes(locationType1 as string);
    const isType2Airport = ['airport', 'cruiseport'].includes(locationType2 as string);

    if (locationType1 && locationType2) {
        tripType =
            isType1Airport && isType2Airport
                ? 'airportToAirport'
                : isType1Airport
                  ? 'fromAirport'
                  : isType2Airport
                    ? 'toAirport'
                    : 'nonAirport';
    }

    return tripType;
});

const depLabels = computed(() => {
    let fieldLabel: string;
    switch (tripType.value) {
        case 'toAirport':
            fieldLabel = 'Flight Departure';
            break;
        case 'fromAirport':
        case 'airportToAirport':
            fieldLabel = 'Flight Arrival';
            break;
        default:
            fieldLabel = 'Pick Up';
            break;
    }

    return {
        global: `${fieldLabel} Date & Time`,
        date: `${fieldLabel} Date`,
        time: `${fieldLabel} Time`,
    };
});

const repLabels = computed(() => {
    let fieldLabel: string;
    switch (tripType.value) {
        case 'toAirport':
            fieldLabel = 'Return Flight Arrival';
            break;
        case 'fromAirport':
            fieldLabel = 'Return Flight Departure';
            break;
        case 'airportToAirport':
            fieldLabel = 'Return Flight Arrival';
            break;
        default:
            fieldLabel = 'Return Pick Up';
            break;
    }

    return {
        global: `${fieldLabel} Date & Time`,
        date: `${fieldLabel} Date`,
        time: `${fieldLabel} Time`,
    };
});

const formatDateTime = (dateTimeObj) => {
    if (!dateTimeObj.date || !dateTimeObj.time) {
        return null;
    }
    const date = new Date(dateTimeObj.date);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0'
    )} ${dateTimeObj.time}:00`;
};

const updateDates = (val: any) => {
    formData.value.dateDep = val.start_date;
    formData.value.dateRep = val.end_date;
};

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        const query: ISFSearch = {
            trip_type: tripType.value,
            pickup: {
                place_id: formData.value.pickUp!.place_id!,
            },
            dropoff: {
                place_id: formData.value.dropOff!.place_id!,
            },
            pickup_date: tripType.value === 'nonAirport' ? formatDateTime(formData.value.dateDep) : null,
            dropoff_date: tripType.value != 'nonAirport' ? formatDateTime(formData.value.dateDep) : null,
            return_pickup_date: tripType.value === 'nonAirport' ? formatDateTime(formData.value.dateRep) : null,
            return_dropoff_date: tripType.value != 'nonAirport' ? formatDateTime(formData.value.dateRep) : null,
            passengers: Number(formData.value.passengers),
            return: formData.value.returnTrip,
            referred_by: '10',
        };
        emit('submitSearch', query, true);
    }
};
</script>

<style scoped lang="postcss">
.form {
    .grid {
        @apply gap-x-3;
    }
    :deep(.grid) {
        .input-group-container {
            max-width: unset !important;
        }
    }
    .custom-label {
        @apply text-center p-1;
        small {
            @apply text-[0.8rem] font-normal uppercase text-white;
            a {
                @apply text-[#34b4f5] hover:text-[#0a8fd2] hover:underline;
            }
        }
    }
}
.input-group-container {
    max-width: unset !important;
}
</style>
